import { lazy, Suspense } from 'react';
import './App.scss';

import { BrowserRouter, Route, Routes } from "react-router-dom";

const ARClothingLandingPage = lazy(() => import("./pages/store"));
const Home = lazy(() => import("./pages/home"));
const ARTShirtDetailsPage = lazy(() => import("./pages/t-shirt-details"));
const SingleARTShirtDetailsPage = lazy(() => import("./pages/product-details"));
const AdminChatPage = lazy(() => import("./pages/admin-chat"));
const Dashboard = lazy(() => import("./pages/dashboard"));

function App() {

    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/store">
                        <Route path='' element={<ARClothingLandingPage />}></Route>
                        <Route path='shirts'>
                            <Route index element={<ARTShirtDetailsPage />}></Route>
                            <Route path=':shirtid' element={<SingleARTShirtDetailsPage/>}></Route>
                        </Route>
                        <Route path='enquiry/*' element={<AdminChatPage />}></Route>
                    </Route>
                    <Route path="/dashboard/*" element={<Dashboard />}></Route>
                    <Route path='/' element={<Home />}>
                        <Route path='track/:trackerId' element={<Home />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
